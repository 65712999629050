<template>
  <div class="grid grid-cols-2 pt-4 gap-4">
    <img
      v-for="(image, index) in images"
      :key="index"
      :src="image.imageurl"
      alt="Image"
      class="w-full h-auto"
    />
  </div>

  <!-- <div
    class="
      grid grid-cols-1
      sm:grid-cols-2
      md:grid-cols-3
      lg:grid-cols-4
      gap-4
      pt-4
    "
  >
    <div v-for="(image, index) in images" :key="index" class="relative">
      <img :src="image.imageurl" alt="Image" class="w-full h-auto rounded" />
    </div>
  </div> -->
</template>

<script setup>
import { computed, defineProps } from 'vue';
const props = defineProps({
  fundraiser: {
    type: Object,
  },
});
const images = computed(() => {
  return props.fundraiser.images;
});
</script>

<style></style>
